<template>
  <div>
    <el-card class="goods-card" @click.native="diaShow(goodsItem,dialogVisible = true)" :body-style="{ padding: '15px' }">
      <img :src="goodsItem.goods_images[0]" class="image">
      <div class="flex flex-t">
        <div class="price">￥{{goodsItem.goods_price}}</div>
        <div>{{goodsItem.goods_weight}}g/件</div>
      </div>
      <div class="flex flex-b">
        <div>{{goodsItem.goods_name}}</div>
        <div>{{goodsItem.goods_storage}}</div>
      </div>
      <div style="display: flex;justify-content: flex-end;align-items: center;" >
        <el-button type="primary" size="mini" @click.stop="xiazai">下载素材</el-button>
      </div>
    </el-card>
    <goods-dialog ref="sons"></goods-dialog>
  </div>
</template>

<script>
import goodsDialog from '@/components/goodsDialog.vue'
export default {
  components: {
    goodsDialog
  },
  props: {
    goodsItem: Object
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  methods: {
    diaShow (item, goodsItem) {
      // console.log(3333, item)
      this.$refs.sons.dialogShow(item, goodsItem)
    },
    xiazai() {
      const {zipurl} = this.goodsItem
      if(zipurl.length <= 0) {
        this.$message({
          message: '暂无素材下载',
          type: 'warning'
        });
        return
      }
      location.href = zipurl
    }
    // 点击每个商品跳转
    // toMall (goodsItem) {
    //   console.log(goodsItem)
    //   // this.$router.push('/goods')
    // }
  }
}
</script>

<style scoped src="styles/views/index/hotgoodscard.css">
</style>
